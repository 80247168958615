<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button *ngIf="!preventBackNavigation" (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="flow" padding>
  <ion-label class="flow__title" stacked>How many vehicles do you need inspected?</ion-label>
  <ion-select
    [placeholder]="props.service.vehicleGroupServiceTitle(1)"
    [interfaceOptions]="selectOptions"
    (ionChange)="didSelectVehicleCount(form.vehicleCount!)"
    [(ngModel)]="form.vehicleCount"
    icon-chevron-down
    inputstyle
  >
    <ion-select-option
      *ngFor="let option of vehicleCountOptions"
      [value]="vehicleCountOptions.indexOf(option) + 1"
      >{{option}}</ion-select-option
    >
  </ion-select>
</ion-content>

<ion-footer>
  <ysh-curved-actions-block>
    <button ysh-button-block (click)="didSelectVehicleCount(form.vehicleCount!)">
      Continue with {{form.vehicleCount}} Vehicles
    </button>
  </ysh-curved-actions-block>
</ion-footer>
