<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content padding>
  <ion-label class="flow__title" stacked>Please confirm your order location</ion-label>
  <ion-input
    [readonly]="true"
    [value]="selectedAddress?.address.locationName"
    (click)="didTapAddressField()"
    (ionFocus)="didTapAddressField()"
    placeholder="Add Address"
    icon-chevron-down
  ></ion-input>
  <button ysh-button-block [disabled]="!validates()" (click)="didTapContinue()">
    Confirm Address
  </button>
</ion-content>
