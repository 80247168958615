<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <div class="intro">
    <h1 class="intro__heading">
      Vehicle added! Do you want to add any more vehicles to your inspection appointment?
    </h1>
    <p class="intro__status" [ngClass]="{'unavailable': !form.slotCount || error }">
      <ng-container *ngIf="loading">
        <b>Loading appointment times</b> for your current selection:
        <ion-spinner name="dots"></ion-spinner>
      </ng-container>
      <ng-container *ngIf="!loading && !error">
        <em><b>{{form.slotCount}}</b> appointment times are available</em> for the
        <b>{{form.appointmentDuration}} minutes</b> required for your current selection:
      </ng-container>
      <ng-container *ngIf="!loading && error"><em>Error: {{error}}</em></ng-container>
    </p>
  </div>
  <ng-container *ngFor="let service of form.services; index as i">
    <ysh-service-added-card
      [serviceTitle]="service.title"
      [servicePrice]="service.price | currency:'USD':'symbol':'1.0-0'"
      [serviceDuration]="service.durationString"
      [allowDelete]="form.services.length > 1"
      (onTapDelete)="didTapDeleteService(i)"
    ></ysh-service-added-card>
  </ng-container>

  <button class="button-add-vehicle" (click)="didTapAddVehicle()">Add another vehicle</button>
</ion-content>

<ion-footer>
  <ysh-curved-actions-block>
    <button ysh-button-block (click)="didTapNext()" [disabled]="!validates">
      <ng-container *ngIf="form.slotCount || loading">Next: Choose Appointment Time</ng-container>
      <ng-container *ngIf="!loading && !form.slotCount">No Availability</ng-container>
    </button>
  </ysh-curved-actions-block>
</ion-footer>
