<ion-header>
  <ysh-service-navbar
    [fuelDiscount]="(service$ | async)?.perGallonDiscount"
    [isSourcedFromUpsellFlow]="isSourcedFromUpSellFlow"
    [service]="(service$ | async)?.serviceType.name"
    [hideBackButton]="preventBackNavigation"
    (didTapPriceDetail)="didTapPriceDetail()"
    (didTapBack)="didTapBack()"
  ></ysh-service-navbar>
</ion-header>
<ion-content #content>
  <div class="service-header" [class.shadow]="showShadow">
    <ysh-service-header
      *ngIf="service$ | async as service"
      [service]="service"
      [hideFuelDiscount]="hideFuelDiscount"
    >
      <ysh-loyalty-stars
        *ngIf="stars$ | async as stars"
        [stars]="service.price ? stars * service.price : stars"
        [postfix]="!service.price && 'per dollar'"
        [color]="(loyaltyService.currentProgram$ | async)?.fullTier?.color"
        [colorIsDark]="(loyaltyService.currentProgram$ | async)?.fullTier?.colorIsDark"
      ></ysh-loyalty-stars>
    </ysh-service-header>
  </div>

  <div class="service-content" [ngClass]="(service$ | async)?.serviceType?.name">
    <!-- Add/Edit Controls  -->
    <ysh-service-tools
      [serviceGroup]="serviceGroup"
      [order]="order$ | async"
      [visit]="nextVisit$ | async"
      [serviceOrder]="serviceOrder$ | async"
      [vehicleSubscription]="vehicleSubscription$ | async "
      [serviceSubscription]="serviceSubscription$ | async "
      [selectedService]="service$ | async"
      [vehicle]="vehicle"
      [fuelTypes]="fuelTypes"
      [hideButtons]="showConfirm"
      (onServiceChange)="didSelectService($event)"
    ></ysh-service-tools>

    <ysh-service-credit
      *ngIf="showCredit"
      [selectedVehicle]="vehicleService.selectedVehicle$ | async"
      [selectedAddress]="addressService.selectedAddress$ | async"
      [serviceType]="serviceGroup.serviceType"
    >
    </ysh-service-credit>
    <!-- About/Status Tabs -->
    <ysh-service-tabs [service]="service$ | async" [hideTabs]="!(nextVisit$ | async)" [faqs]="faqs">
      <!-- Service Schedule Status  -->
      <ysh-service-status
        [service]="service$ | async"
        [visit]="nextVisit$ | async"
        [subscribed]="hasSubscription$ | async"
      >
      </ysh-service-status>
      <!-- Fuel Related Status  -->
      <ysh-service-status-fuel
        *ngIf="(service$ | async)?.serviceType.name === 'gas_refill'"
        [vehicle]="vehicle"
        [fuelTypes]="fuelTypes"
      ></ysh-service-status-fuel>
      <!-- Tire Related Status  -->
      <ysh-service-status-tire
        *ngIf="(service$ | async)?.serviceType.name === 'tires'"
      ></ysh-service-status-tire>
      <!-- Frequency Selector -->
      <ysh-service-repeat
        *ngIf="hasSubscription$ | async"
        [serviceSubscription]="serviceSubscription$ | async"
        [vehicleSubscription]="vehicleSubscription$ | async"
      ></ysh-service-repeat>
    </ysh-service-tabs>
  </div>
</ion-content>

<ion-footer *ngIf="showConfirm">
  <ion-toolbar no-padding>
    <ysh-curved-actions-block>
      <button ysh-button-block size="large" action="primary" (click)="didTapConfirm()">
        <ng-container *ngIf="!(selectedService$ | async)?.allowMultipleVehicles">
          Confirm Service
        </ng-container>
        <ng-container *ngIf="(selectedService$ | async)?.allowMultipleVehicles">
          Add To Appointment
        </ng-container>
      </button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
