<div class="card">
  <h6 class="card__heading">Your Account Information</h6>
  <ul class="card__info">
    <li class="card__info__name">
      <ion-icon name="person-circle-sharp"></ion-icon>
      {{name}}
    </li>
    <li class="card__info__email">
      <ion-icon name="mail-open-sharp"></ion-icon>
      {{email}}
    </li>
    <li class="card__info__phone">
      <ion-icon name="call-sharp"></ion-icon>
      {{phone | formatPhoneNumber}}
    </li>
    <li *ngIf="vehicle" class="card__info__vehicle">
      <ion-icon name="car-sport-sharp"></ion-icon>
      {{vehicle}}
    </li>
  </ul>
  <div class="card__badge">
    <ion-icon name="person-circle-outline"></ion-icon>
  </div>
</div>
