<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="flow" padding>
  <ion-label class="flow__title" stacked>To save time add your vehicle information now:</ion-label>
  <div *ngIf="placeholderVehicles$">
    <ng-container *ngFor="let vehicle of (placeholderVehicles$ | async); index as i;">
      <ysh-input
        readonly="true"
        appearence="outline"
        [placeholder]="'Vehicle ' + (i + 1)"
        [value]="vehicle.vehicleModel.placeholder? null : vehicle.description"
        (click)="didTapVehicle(vehicle)"
      ></ysh-input>
    </ng-container>
  </div>
</ion-content>

<ion-footer>
  <ysh-curved-actions-block>
    <button ysh-button-block (click)="didTapSkipForNow()">
      {{didUpdateVehicle ? "Continue" : "Skip for Now"}}
    </button>
  </ysh-curved-actions-block>
</ion-footer>
