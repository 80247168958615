<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow" padding>
  <ion-label class="flow__title" stacked>
    Appointments are available! Add your info to see scheduling options!
  </ion-label>
  <div class="input-group">
    <ion-input
      #inputFirstName
      [(ngModel)]="formData.firstName"
      type="text"
      placeholder="First Name"
      (keyup.enter)="didTapEnter()"
    ></ion-input>
    <ion-input
      #inputLastName
      [(ngModel)]="formData.lastName"
      type="text"
      placeholder="Last Name"
      (keyup.enter)="didTapEnter()"
    ></ion-input>
  </div>
  <ion-input
    #inputEmail
    [(ngModel)]="formData.email"
    type="email"
    placeholder="Email Address"
    (keyup.enter)="didTapEnter()"
  ></ion-input>
  <input
    #inputPhone
    [textMask]="{mask: phoneMask, guide: false}"
    [(ngModel)]="formData.phone"
    type="tel"
    placeholder="Phone (415) 555-1234"
    (keyup.enter)="didTapEnter()"
  />
  <ion-input
    #inputZip
    [(ngModel)]="zipCode"
    *ngIf="props.webflowVariant.collectZipAtSignup"
    inputstyle
    type="number"
    placeholder="Zip Code"
  ></ion-input>
  <ng-template *ngTemplateOutlet="searchField"></ng-template>
  <ion-input
    *ngIf="formData.inviteCode"
    #inputInviteCode
    [(ngModel)]="formData.inviteCode"
    type="text"
    disabled="true"
    placeholder="Invite Code"
  ></ion-input>
  <button ysh-button-block [disabled]="!formValidates()" (click)="didTapDone()">Continue</button>
  <ysh-legal-text></ysh-legal-text>
</ion-content>

<ng-template #searchField>
  <div
    class="search-field-group"
    no-padding
    no-border
    *ngIf="props.webflowVariant.collectAddressAtSignup"
  >
    <ion-label *ngIf="platform.is('mobile')" [class.placeholder]="!formData?.locationName " (click)="didTapNewAddress()">
      {{formData?.locationName ? formData.locationName : "Where is your vehicle located?"}}
    </ion-label>

    <!-- Uses google places autocomplete popup -->
    <ion-searchbar
      *ngIf="platform.is('desktop')"
      #searchbar
      inputstyle
      (click)="didTapSearch()"
      placeholder="Where is your vehicle located?"
    >
    </ion-searchbar>
  </div>
</ng-template>
