<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <h1>Yoshi Inspection Services Disclaimer</h1>
  <p class="yoshi-legal">
    By making an appointment for mobile or remote inspection of your vehicle through the Service,
    you agree to our
    <span (click)="openLink('https://www.startyoshi.com/terms/')">Terms of Service.</span>
  </p>
  <p>
    <b>Specifically, you hereby acknowledge and agree to the following:</b> The Service is intended
    to provide an evaluation of your vehicle’s suitability for usage with a transportation network
    company or “TNC” as may be defined in the state in which the Service is provided and solely as
    of a particular moment and time or by a TNC like Uber, Lyft, or other similar service providers.
    ANY INSPECTION THAT MAY BE PROVIDED AS PART OF THE SERVICES IS NOT A SAFETY INSPECTION. Yoshi
    makes no warranty or claim whatsoever as to the safety or functionality of your vehicle, and
    expressly disclaims any and all liability arising from or related to safety issues or any other
    compliance considerations with your vehicle or operation thereof with a TNC. You expressly agree
    and acknowledge that ensuring the safety of your vehicle is your own responsibility, and that if
    you have any doubts as to your vehicle’s safety, functionality, or eligibility for use with a
    TNC, you shall have a safety inspection conducted on your vehicle from a third-party qualified
    to provide such inspections. YOU HEREBY EXPRESSLY WAIVE AND RELEASE YOSHI FROM ANY AND ALL
    CLAIMS RELATED TO THE SAFETY, FUNCTIONALITY AND OPERATION OF YOUR VEHICLE, AND EXPRESSLY ASSUME
    THE RISKS INHERENT IN DRIVING FOR A TNC.
  </p>
  <p>
    <b>Inspection Components:</b> Mobile or remote vehicle inspections provided through the Service
    will evaluate your vehicle based on our standard 19-point [Vehicle Inspection Form]. Some states
    require use of an inspection form that may differ from our Vehicle Inspection Form and may
    require additional inspection components. Yoshi cannot guarantee that your vehicle will pass
    inspection from our Vehicle Inspection Form or that your vehicle will otherwise be approved for
    use by a specific TNC like Uber of Lyft. Upon completion of a remote vehicle inspection through
    the Service, regardless of result, Yoshi will be entitled to payment for use of the Service. For
    further information please see our Terms of Service.
  </p>
</ion-content>
<ion-footer>
  <button ysh-button-block (click)="didTapAccept()">I Agree</button>
</ion-footer>
