<ion-content class="vehicle-profile">
  <div class="vehicle-profile__header">
    <div class="actions">
      <button ysh-button-icon name="back" (click)="didTapDismiss()"></button>
    </div>
    <div class="display-photo" (click)="didTapVehicleImage()">
      <div class="image-wrapper">
        <img [src]="props.vehicle.vehicleImageUrl || 'assets/images/vehicle-placeholder.svg'" />
      </div>
    </div>
  </div>

  <div class="vehicle-profile__content">
    <!-- general info -->
    <ysh-vehicle-profile-information
      [vehicle]="props.vehicle"
      (onDidTapEdit)="didTapEdit($event)"
    ></ysh-vehicle-profile-information>

    <!-- gallery -->
    <ng-container *ngIf="photoStory.length">
      <div class="vehicle-profile__content__photo-gallery vehicle-info-block">
        <ysh-gallery
          slidesPerView="2.5"
          rounded="true"
          [showMore]="true"
          [slides]="photosAsUrls"
          (onViewMoreTapped)="didTapViewMore()"
          (onSlideTap)="didTapGalleryImage($event)"
        ></ysh-gallery>
      </div>
    </ng-container>

    <!-- performance -->
    <div class="vehicle-profile__content__performance vehicle-info-block">
      <ion-item no-border detail="true" class="performance-item" (click)="didTapMileage()">
        <img src="assets/images/vehicle-profile/icon-mileage.svg" class="icon" />
        <div class="detail">
          <p class="label">Mileage</p>
          <p class="status" *ngIf="mileages.length">{{mileages[0].miles | number:'1.0':'en-US'}}</p>
          <p class="status" *ngIf="!mileages.length">Add Miles</p>
        </div>
      </ion-item>
      <ion-item
        *ngIf="recalls.length"
        no-border
        detail="true"
        class="performance-item"
        (click)="didTapRecalls()"
      >
        <img src="assets/images/vehicle-profile/icon-recalls.svg" class="icon" />
        <div class="detail">
          <p class="label">Recalls</p>
          <p class="status">{{recalls.length}} {{recalls.length > 1 ? 'recalls' : 'recall'}}</p>
        </div>
      </ion-item>
    </div>

    <!-- fuel status -->
    <ng-container *ngIf="props.vehicle?.fuelLevel">
      <div class="vehicle-profile__content__fuel-status vehicle-info-block">
        <ysh-fuel-status [vehicle]="props.vehicle"></ysh-fuel-status>
      </div>
    </ng-container>

    <!-- tire status -->
    <div class="vehicle-profile__content__tire-status vehicle-info-block">
      <ysh-service-status-tire></ysh-service-status-tire>
    </div>
  </div>
</ion-content>
