<ysh-loading-overlay
  *ngIf="uberService.appIsEmbeddedInUber && !uberDataLoaded"
  spinnerName="dots"
></ysh-loading-overlay>

<ion-content>
  <main>
    <img class="image" src="{{webflowVariant.mainImage}}" />
    <div class="text">
      <h1 class="text__heading" [innerHTML]="webflowVariant.mainHeading"></h1>
      <p class="text__caption" [innerHTML]="webflowVariant.mainCaption"></p>
      <ul class="text__list">
        <li *ngFor="let mainListItem of webflowVariant.mainList" [innerHTML]="mainListItem"></li>
      </ul>

      <ysh-more-details
        *ngIf="webflowVariant.mainMoreDetails"
        details="{{webflowVariant.mainMoreDetails}}"
        faqs="{{faq}}"
        (tapFaqs)="didTapFAQS()"
      ></ysh-more-details>

      <div class="text__fineprint" *ngIf="webflowVariant.mainFineprint?.length">
        <img src="{{webflowVariant.mainFineprintIcon}}" *ngIf="webflowVariant.mainFineprintIcon?.length">
        <p [innerHTML]="webflowVariant.mainFineprint"></p>
      </div>
    </div>

    <ysh-user-card
      *ngIf="uberData"
      name="{{uberData.firstName}} {{uberData.lastName}}"
      email="{{uberData.email}}"
      phone="{{uberData.phone}}"
      vehicle="{{uberData.vehicleDescription}}"
    ></ysh-user-card>

    <div class="base">
      <div class="base__faqs" *ngIf="faq?.length && !webflowVariant.mainMoreDetails">
        <a (click)="didTapFAQS()">Read the FAQs</a> for additional information.
      </div>
      <div class="base__login" *ngIf="!uberService.appIsEmbeddedInUber">
        Have a Yoshi account? <a (click)="didTapLogin()">Sign in</a>
      </div>
    </div>
  </main>
</ion-content>

<ion-footer>
  <ysh-legal-text
    *ngIf="webflowVariant.footerLegalText && uberService.appIsEmbeddedInUber && uberDataLoaded"
  ></ysh-legal-text>
  <ng-template *ngTemplateOutlet="searchField"></ng-template>
  <ng-template *ngTemplateOutlet="startButton"></ng-template>
</ion-footer>

<ng-template #searchField>
  <ion-item class="search-field-group" no-padding no-border *ngIf="!webflowVariant.virtual">
    <ion-label *ngIf="platform.is('mobile')" class="placeholder label" (click)="didTapNewAddress()">
      <ion-icon name="location-outline"></ion-icon>
      {{formData?.locationName ? formData.locationName : "Enter Service Address"}}
    </ion-label>
    <ion-searchbar
      *ngIf="platform.is('desktop')"
      #searchbar
      webstyle
      (click)="didTapSearch()"
      placeholder="Enter Service Address"
      search-icon="location-outline"
    >
    </ion-searchbar>
    <button [disabled]="!addressValidates()" (click)="didTapDone()">
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </button>
  </ion-item>
</ng-template>

<ng-template #startButton>
  <button
    [disabled]="loadingVirtualServices || !serviceGroup"
    *ngIf="webflowVariant.virtual"
    class="start-button"
    (click)="didTapSchedule()"
    ysh-button-block
    large
  >
    <span *ngIf="serviceGroup">
      {{webflowVariant.startButtonLabel ? webflowVariant.startButtonLabel : 'Tap To Schedule'}}
    </span>
    <ion-spinner *ngIf="loadingVirtualServices" name="dots" color="white"></ion-spinner>
    <span *ngIf="!serviceGroup && !loadingVirtualServices">Service Unavailable</span>
  </button>
</ng-template>
