<ion-header>
  <ion-toolbar>
    <ion-buttons start>
      <ion-button (click)="didTapBack()">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="typed-flow flow" padding>
  <ion-label class="flow__title" stacked>Greetings, please enter your phone number</ion-label>
  <input
    #inputPhone
    [textMask]="{mask: phoneMask, guide: false}"
    [(ngModel)]="formData.phone"
    type="tel"
    placeholder="(415) 555-1234"
    (keyup.enter)="didTapEnter()"
  />
  <button ysh-button-block [disabled]="!phoneValidates()" (click)="didTapEnter()">Continue</button>
</ion-content>
