<div class="service-tools">

  <ysh-faux-select-dropdown
    *ngIf="tierOptions.length > 1"
    [label]="tierOptions.length + ' Options'"
    [value]="selectedService.title + ' - $' + selectedService.price"
    (click)="didTapFauxSelectDropdown()"
  ></ysh-faux-select-dropdown>

  <ysh-dropdown
    *ngIf="serviceGroup.serviceType.name === 'gas_refill'"
    [options]="fuelTypeOptions"
    [selectedIndex]="selectedFuelIndex"
    [placeholder]=""
    (onChange)="didChangeFuelType($event)"
    [showCountLabel]="false"
  ></ysh-dropdown>

  <div class="service-is-added" *ngIf="hasOrder && !hideButtons" (click)="didTapEdit()">
    <button ysh-button-block action="succeeded" class="added">
      PENDING
      <ng-container *ngIf="serviceGroup.services.length && selectedService.price > 0 ">
        <span class="separator">|</span>
        <span>{{selectedService.price | yshCurrency}}</span>
      </ng-container>
    </button>
    <ion-button class="adjust-schedule">
      <ion-icon name="settings-sharp"></ion-icon>
    </ion-button>
  </div>
  <div class="service-is-available" *ngIf="!hasOrder && !hideButtons">
    <ysh-add-service
      displayMode="block"
      (didTapAddService)="didTapAddService()"
      [price]="selectedService?.price"
    >
    </ysh-add-service>
  </div>
</div>
