<ion-header>
  <ion-toolbar>
    <ion-buttons>
      <ion-button (click)="didTapDismiss()">
        <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content padding>
  <h1>Choose a service option:</h1>
  <ysh-service-select-card
    *ngFor="let service of services; index as i"
    [serviceTitle]="service.title"
    [servicePrice]="service.price"
    [serviceDuration]="service.stopDuration"
    [serviceDescription]="service.shortDescription"
    [selected]="i === selectedIndex"
    (click)="didTapCard(i)"
  ></ysh-service-select-card>
</ion-content>
