<ion-header>
  <ysh-order-flow-navbar
    [showFaqBtn]="false"
    (onDismiss)="didTapDismiss()"
    (onBack)="didTapBack()"
    [showDismissBtn]="showDismissBtn"
    [showBackBtn]="!hideBackButton"
    [showInDarkTheme]="showInDarkTheme"
  >
  </ysh-order-flow-navbar>
</ion-header>
<ion-content class="typed-flow flow">
  <div class="credit-card-flow flowItem">
    <div class="label">
      <ion-label class="flow__title" stacked>Please enter your credit card information.</ion-label>
    </div>
    <form>
      <ion-grid
        class="credit-card-form form-padding"
        [ngClass]="{'outline-input-style' : outlineInputStyle}"
      >
        <ion-row class="number-row">
          <div id="payment_card_number" ion-col class="element number"></div>
        </ion-row>
        <ion-row>
          <div id="payment_card_expiry" ion-col class="element expiry"></div>
          <div id="payment_card_cvc" class="element" ion-col></div>
          <input
            [(ngModel)]="postalCode"
            [ngModelOptions]="{standalone: true}"
            type="tel"
            class="postal-code"
            ion-col
            maxlength="5"
            placeholder="Zip Code"
          />
        </ion-row>

        <ion-row class="errors" *ngIf="hasErrors">
          <p *ngIf="cardNumberError">{{cardNumberError}}</p>
          <p *ngIf="cardExpiryError">{{cardExpiryError}}</p>
          <p *ngIf="cardCvcError">{{cardCvcError}}</p>
        </ion-row>

        <ion-row class="disclaimer">
          <ion-col>
            <p class="note">
              Your credit card info is handled securely by Stripe™! We won't charge your card until
              your order is complete.
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar no-padding>
    <ysh-curved-actions-block>
      <button (click)="didTapDone()" [disabled]="!validates" ysh-button-block>
        <ion-icon name="lock-closed" class="lock-icon"></ion-icon>Add Card
      </button>
    </ysh-curved-actions-block>
  </ion-toolbar>
</ion-footer>
