<div class="vehicle-information-component">
  <div class="vehicle-information">
    <ysh-vehicle-image
      large
      [src]="selectedVehicle?.vehicleImageUrl"
      [selectedVehicle]="selectedVehicle"
      (onTap)="didTapVehicleImage()"
      [displayMode]="hasUnseenPhotos? 'highlighted': hasPhotoStory? 'grayed': 'clear'"
    ></ysh-vehicle-image>
    <div class="vehicle-information__text" (click)="didTapVehicleInfo()">
      <h2 class="vehicle-information__text__name">{{selectedVehicle?.nickname}}</h2>
      <p *ngIf="!showEdit" class="vehicle-information__text__info">
        License: {{selectedVehicle?.license || "Add License"}}
      </p>
      <p *ngIf="!showEdit" class="vehicle-information__text__info">
        VIN: {{selectedVehicle?.vin || "Add VIN"}}
      </p>
      <p class="vehicle-information__text__info">{{selectedVehicle?.fuelType?.description}}</p>
      <p *ngIf="showEdit" class="vehicle-information__text__edit">Edit Order</p>
    </div>
  </div>
</div>
